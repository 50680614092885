import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/LitreryClub/2024/PickAndSpeach/1.jpg';
// import p2 from 'assests/Photos/Clubs/LitreryClub/2024/PickAndSpeach/2.jpg';
// import p3 from 'assests/Photos/Clubs/LitreryClub/2024/PickAndSpeach/3.jpg';
// import p4 from 'assests/Photos/Clubs/LitreryClub/2024/PickAndSpeach/4.jpg';
// import p5 from 'assests/Photos/Clubs/LitreryClub/2024/PickAndSpeach/5.jpg';
// import p6 from 'assests/Photos/Clubs/LitreryClub/2024/PickAndSpeach/6.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../Literaryclub2023/components/Sidebar2024';
import Archive2023 from '../Literaryclub2023/components/Archive2023';
import LiterarySidebar from '../Literaryclub2023/components/LiterarySidebar';

const PickAndSpeak2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_url}/home/events-activities/association/LitreryClub/PickAndSpeach/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/LitreryClub/PickAndSpeach/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/LitreryClub/PickAndSpeach/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/LitreryClub/PickAndSpeach/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/association/LitreryClub/PickAndSpeach/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/association/LitreryClub/PickAndSpeach/6.webp`;
  
    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1.4,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p6,
            source: p6,
            rows: 1.4,
            cols: 2,
        }
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                Pick and Speak about the topic given or picture shown
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 1 & 2  &  Date: 26 July 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The final round of English CCA, held on Friday, 26 July brought out the oratory skill and
                                ardour of participants. It was an individual performance that provided the participants
                                with an opportunity to showcase their composure and confidence. They were prompt in
                                their presentation, they were able to recollect and frame relevant sentences within the
                                short span of time.
                                <br></br>
                                They displayed a good sense of awareness about the world around them. The students
                                came up with descriptive sentences and facts about the topics given like rainbow, trees,
                                flowers, hobbies etc.
                                <br></br>
                                On the whole, the competition was highlighted with poise, performance, house spirit and
                                confidence.
                                <br></br>
                                
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "Nothing can dim the light which shines from within…"

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        
                        <Box marginBottom={4}>
                            <Sidebar2024/>
                        </Box>
                        {/* <Box marginBottom={4}>
                                    <Archive2023/>
                        </Box> */}
                        <Box marginBottom={4}>
                                    <LiterarySidebar/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default PickAndSpeak2024;